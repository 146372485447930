<script lang="ts">
  import AboutPayment from './AboutPayment.svelte';
import logo from './assets/AIFAN_Alpha_logo.png';
</script>

<svelte:head>
  <link href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap" rel="stylesheet" />
</svelte:head>

<header class="header">
  <div class="header-container">
    <div class="left-side">
      <div class="menu-opener">
        <slot name="menu-opener" />
      </div>
      <h1 class="header-title">
        <a href="/"><img src={logo} alt="AIFAN" /></a>
      </h1>
      <div class="legend">新たな創作の形を応援する支援サイト</div>
    </div>
    <div class="right-side">
      <slot name="right-menu" />
    </div>
  </div>
  <div class="important-notice">
    <p><AboutPayment /></p>
  </div>
</header>

<style lang="scss">
  $sp-width: 456px;
  $tb-width: 880px;
  header {
    position: sticky;
    top: 0;
    z-index: 1000;
  }

  .important-notice {
    width: fit-content;
    margin: auto;

    > :global(p) {
      margin: 0;
      height: 1.5rem;

      :global(a) {
        color: var(--primary-color);
        text-decoration: underline;

        &::before {
          content: "【重要なお知らせ】";
        }
      }
    }
  }

  .header {
    background: #fff;
    color: var(--primary-color);
    height: 5rem;
    font-weight: bold;
    padding-top: 0.5rem;
    border-bottom: 1px solid var(--border-gray);
  }

  .header-container {
    display: flex;
    justify-content: space-between;
    max-width: 80rem;
    height: 3rem;
    margin: auto;
  }

  .left-side {
    display: flex;
    flex: 1;
    align-items: center;

    .legend {
      margin-left: 1rem;
    }
  }

  .right-side {
    display: flex;
    grid-column-gap: 1rem;
  }

  h1.header-title {
    width: 10rem;
    height: auto;

    img {
      width: 100%;
      height: auto;
    }
    a {
      display: flex;
      align-items: center;
      width: 100%;
      height: fit-content;
    }
  }
  @media screen and (max-width: #{$tb-width}) {
    .header {
      width: 100%;
      height: 5rem;
      padding: 0;
      font-size: 0;

      .important-notice {
        font-size: 0.8rem;
      }
    }

    .header-container {
      align-items: center;
      max-width: 100%;
      height: 3rem;
      padding: 0 0.25rem 0 1rem;
    }

    .left-side {
      .legend {
        margin-left: 0.5rem;
      }
    }

    h1.header-title {
      width: 7rem;
    }
    .right-side {
      display: flex;
      grid-column-gap: 0.5rem;
    }
  }
</style>
