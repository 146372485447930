function __check_browser() {
  if (typeof window === 'undefined') {
    return false;
  }

  if ('document' in window && 'cookie' in window.document) {
    return true;
  }

  return false;
}

export function set(key: string, value: string, maxAge?: number) {
  if (!__check_browser()) {
    return;
  }

  key = encodeURIComponent(key);
  value = encodeURIComponent(value);
  maxAge = maxAge ?? 31536000;

  document.cookie = `${key}=${value};max-age=${maxAge};samesite=strict;path=/;domain=${location.hostname}`;
}

export function get(key: string): string | undefined {
  if (!__check_browser()) {
    return;
  }

  key = encodeURIComponent(key);

  const cookies = Object.fromEntries(
    document.cookie.split(';').map((cookie): [string, string] => {
      const [name, ...value] = cookie.trim().split('=') as [string, ...string[]];
      return [name, value.join('=')];
    }),
  );

  const v = cookies[key];

  if (v === undefined) {
    return;
  }

  return decodeURIComponent(v);
}

export function del(key: string) {
  if (!__check_browser()) {
    return;
  }

  key = encodeURIComponent(key);
  document.cookie = `${key}=null;max-age=0;samesite=strict;path=/;domain=${location.hostname};`;
}

if (__check_browser()) {
  (window as any).storage = {
    set,
    get,
    del,
  };
}
