export interface Channel {
  /**
   * Firestore上のオブジェクトID
   * @type {string}
   */
  id: string;
  /**
   * チャンネルのアイコン
   * @type {string | null}
   */
  icon: string | null;
  /**
   * チャンネルの名前
   * @type {string}
   */
  name: string;
  /**
   * チャンネルの表示ID
   */
  display_name: string;
  /**
   * R18コンテンツを許可するか
   * @type {boolean}
   */
  allow_r18: boolean;
  /**
   * 登録日時
   */
  created_at: number;
}

export interface ChannelDetail extends Channel {
  /**
   * 自己紹介欄
   * @type {string}
   */
  bio: string;
  /**
   * ヘッダ画像
   * @type {string | null}
   */
  header: string | null;
  /**
   * ツイッターのプロフィールURL
   */
  twitter_url: string | null;
  /**
   * WebページのURL
   */
  web_url: string | null;
}

export interface ChannelStored extends ChannelDetail {
  /**
   * ユーザーID
   * @type {string}
   */
  uid: string;
}

export interface ChannelCreate extends Omit<ChannelDetail, 'id' | 'created_at'> {}

export function isValidChannelCreate(obj: any): obj is ChannelCreate {
  return (
    typeof obj === 'object' &&
    'icon' in obj &&
    (typeof obj.icon === 'string' || obj.icon === null) &&
    'name' in obj &&
    typeof obj.name === 'string' &&
    'display_name' in obj &&
    typeof obj.display_name === 'string' &&
    'allow_r18' in obj &&
    typeof obj.allow_r18 === 'boolean' &&
    'bio' in obj &&
    typeof obj.bio === 'string' &&
    'header' in obj &&
    (typeof obj.header === 'string' || obj.header === null)
  );
}

export const MEANINGLESS_CHANNEL_ID = '<default unknown channel id>';

export default {};
