<script lang="ts">
  import type { Channel } from '../../firebase/api/channel';
  import defaultIcon from '../../assets/default-icon.png';
  import CircularProgress from '@smui/circular-progress/src/CircularProgress.svelte';
  import { authorized, signingOut } from '../../firebase/auth';
  import { del } from '../../utils/storage';
  import { goto } from '../../utils/navigation';
  import { onMount } from 'svelte';

  export let channel: Channel | undefined;
  export let auth = false;

  onMount(() => {
    authorized.then((e) => {
      if (e !== null) {
        auth = true;
      } else {
        auth = false;
      }
    });
  });
</script>

<nav class="menu">
  {#if channel === undefined || !auth}
    <div class="loading">
      <CircularProgress indeterminate class="loading-cp" />
    </div>
  {:else}
    <div class="channel">
      <div class="avatar">
        <a href="/c/{channel.display_name}">
          <img src={channel.icon ?? defaultIcon} alt="{channel.name}のアイコン" />
        </a>
      </div>
      <div class="channel-name"><a href="/c/{channel.display_name}">{channel.name}</a></div>
    </div>
    <div class="post-feed">
      <button class="post-new-feed-immediate" type="button" on:click={() => goto('/feeds/new')}>
        <div>投稿する</div>
      </button>
    </div>
    <div class="links about-accounts">
      <a href="/settings" data-sveltekit-preload-code="eager">チャンネル設定</a>
      <a
        href="/c/{channel?.display_name}"
        on:click={() => goto(`/c/${channel?.display_name}?tab=plans`)}
        data-sveltekit-preload-code="eager"
      >
        投稿管理
      </a>
      <a
        href="/c/{channel?.display_name}?tab=plans"
        on:click={() => goto(`/c/${channel?.display_name}?tab=plans`)}
        data-sveltekit-preload-code="eager"
      >
        プラン管理
      </a>
      <a href="/settings/earnings" data-sveltekit-preload-code="eager">支援金管理</a>
      <a
        href="."
        on:click={(e) => {
          e.preventDefault();
          del('currentCID');
          signingOut();
          goto('/');
          location.reload();
        }}>ログアウト</a
      >
    </div>
    <div class="links about-patreon">
      <a href="/" data-sveltekit-preload-code="eager">支援中タイムライン</a>
      <a href="/supporting" data-sveltekit-preload-code="eager">支援中のクリエイター</a>
    </div>
    <div class="links about-us">
      <a href="/tos">利用規約</a>
      <a href="/scta">特定商取引法に基づく表記</a>
      <a href="/privacy-policy">プライバシーポリシー</a>
      <!--a href="/contact">お問い合わせ</a-->
    </div>
  {/if}
</nav>

<style lang="scss">
  .loading {
    top: 10rem;
    z-index: 500;
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;

    :global(.loading-cp) {
      height: 6rem;
      width: 6rem;
    }
  }

  .links {
    display: flex;
    flex-direction: column;
    grid-row-gap: 0.5rem;
    margin-bottom: 2rem;

    a {
      text-decoration: none;
      color: black;
      font-weight: bold;
      margin: 0;
      text-align: left;
      color: var(--text-gray);

      &:hover {
        filter: brightness(1.2);
        opacity: 1;
        text-decoration: underline;
      }

      &:active {
        opacity: 0.5;
        filter: brightness(0.8);
      }
    }
  }

  .post-feed {
    margin: 2rem auto;

    button {
      transition: 200ms;
      appearance: none;
      border: none;
      background: var(--secondary-bg);
      display: flex;
      height: 3rem;
      width: 100%;
      padding: 0.7rem 1.5rem;
      border-radius: 9in;
      text-decoration: none;
      color: white;
      font-weight: bold;
      margin: 0.5rem auto;
      font-size: 90%;

      &:hover {
        filter: brightness(1.2);
      }

      &:active {
        filter: brightness(0.8);
      }

      div {
        margin: auto;
      }
    }
  }

  nav.menu {
    width: fit-content;
    height: 100%;
    padding-top: 1px;
    padding-right: 2em;
    position: relative;
    margin-left: auto;
  }

  .channel {
    margin: 2rem 0;
    display: flex;
    grid-row-gap: 0.5rem;
    flex-flow: column nowrap;

    .avatar {
      img {
        width: 6rem;
        height: 6rem;
        border-radius: 9in;
        display: block;
        margin: auto;
        border: solid 1px var(--primary-color);
      }
    }

    .channel-name {
      margin: auto;
      font-weight: bold;

      a {
        text-decoration: none;
        color: var(--text-gray);
      }
    }
  }
</style>
