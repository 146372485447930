<script lang="ts">
  import { Text, PrimaryText } from '@smui/list';
</script>

<div class="terms-wrap">
  <ul class="terms-list">
    <li class="terms-item">
      <a href="/tos">利用規約</a>
    </li>
    <li class="terms-item">
      <a href="/privacy-policy">プライバシーポリシー</a>
    </li>
    <li class="terms-item">
      <a href="https://docs.google.com/forms/d/e/1FAIpQLSdW-18QBXqpA7c3AhcxCNpPiNGS1n1GzVwTsUnY75599P3mIw/viewform"
        >お問い合わせ</a
      >
    </li>
  </ul>
</div>

<style lang="scss">
  $sp-width: 456px;
  $tb-width: 880px;
  .terms {
    &-wrap {
      padding: 2.5em 0 0;
    }
    &-list {
      display: flex;
      justify-content: center;
      grid-column-gap: 2.4rem;
      margin: 0;
      padding: 0;
    }
    &-item {
      list-style: none;
      a {
        color: var(--text-gray);
        font-size: 0.8rem;
      }
    }
  }
  @media screen and (max-width: #{$tb-width}) {
    .terms {
      &-wrap {
        padding: 0 0 1.5rem;
        background-color: var(--bg-gray);
      }
      &-list {
        grid-column-gap: 2rem;
      }
      &-item {
        list-style: none;
        a {
          color: var(--text-gray);
          font-size: 0.8rem;
        }
      }
    }
  }
</style>
