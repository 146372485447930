<script lang="ts">
  import { MEANINGLESS_CHANNEL_ID } from '../firebase/common/user';
  import type { Channel } from '../firebase/api/channel';
  import '../firebase/initialization';
  import Header from './Header.svelte';
  import RightSideMenu from './RightSideMenu.svelte';
  import BottomTerms from './BottomTerms.svelte';
  import SideMenu from './sidemenu/SideMenu.svelte';
  import CircularProgress from '@smui/circular-progress';

  export let channels: Record<string, Channel> = {};
  export let currentCID: string | null = MEANINGLESS_CHANNEL_ID;
  export let currentChannel: Channel | null | undefined = undefined;
  export let disallowChangeChannel: boolean = false;
  export let needlessRedirectToCreateChannel: boolean = false;

  let modalShown = true;

  $: currentChannel = currentCID !== null ? channels[currentCID] : null;

  const startTransition = () => {
    modalShown = true;
  };

  const onLinkClick = (e: MouseEvent) => {
    if (e.ctrlKey || e.metaKey || e.shiftKey || e.altKey) return;

    if (!(e.target instanceof HTMLAnchorElement)) return;
    if (e.target.target !== '') return;

    const href = new URL(e.target.href);
    if (href.origin !== location.origin) return;
    if (href.pathname === location.pathname) return;

    modalShown = true;
  };
</script>

<svelte:window on:start-transition={startTransition} on:click={onLinkClick} />

<div class="wrapper">
  <Header>
    <slot name="menu-opener" slot="menu-opener" />
    <RightSideMenu
      slot="right-menu"
      bind:channels
      bind:currentCID
      onReady={() => {
        modalShown = false;
      }}
      {disallowChangeChannel}
      {needlessRedirectToCreateChannel}
    />
  </Header>
  <div class="content" class:no-aside={currentChannel == null}>
    <aside>
      {#if currentChannel != null}
        <SideMenu channel={currentChannel} />
      {/if}
    </aside>
    <main>
      <div class="main-wrap">
        <slot />
      </div>
      <BottomTerms />
    </main>
  </div>
  <div class="modal" class:hidden={!modalShown}>
    <div class="modal-sizer">
      <CircularProgress style="height: 8rem; width: 8rem;" indeterminate />
    </div>
  </div>
</div>

<style lang="scss">
  $sp-width: 456px;
  $tb-width: 880px;
  $sidePadding: calc((100vw - 80rem) / 2);
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    z-index: 10000;
    display: flex;
    justify-content: center;
    opacity: 1;
    transition: opacity 200ms ease-in-out, pointer-events 200ms step-end;

    .modal-sizer {
      margin: auto;
    }

    &.hidden {
      opacity: 0;
      pointer-events: none;
    }
  }

  .wrapper {
    min-height: 100vh;
  }

  .content {
    width: 100%;
    min-height: calc(100vh - 4em);
    height: auto;
    margin: auto;
    display: grid;
  }

  .content {
    grid-template-columns: 0 1fr;

    &:not(.no-aside) {
      grid-template-columns: calc(80rem / 6 + #{$sidePadding}) 1fr;

      main {
        padding: 2em $sidePadding 2em 2em;
      }
      aside {
        padding-left: 6.5rem;
        border-right: 1px solid var(--border-gray);
      }
    }
  }

  main {
    padding: 2rem 0 2em 2em;
    background-color: var(--bg-gray);
    height: calc(100vh - 3.5rem - 4em);
    min-height: -webkit-fill-available;
    overflow-y: auto;
  }
  .main-wrap {
    display: grid;
    min-height: calc(100vh - 4em - 4em);
  }
  @media screen and (max-width: 1440px) {
    .content {
      &:not(.no-aside) {
        aside {
          padding-left: 2.5em;
        }
      }
    }
  }
  @media screen and (max-width: #{$tb-width}) {
    .content {
      min-height: calc(100vh - 3em);
    }

    .content {
      grid-template-columns: 100%;

      &:not(.no-aside) {
        grid-template-columns: 100%;

        main {
          padding: 0;
        }
        aside {
          display: none;
        }
      }
    }

    main {
      padding: 2rem 0 2em 0;
    }
    .main-wrap {
      min-height: calc(100vh - 3em - 3em);
    }
  }
</style>
