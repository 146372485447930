// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

let __app: ReturnType<typeof initializeApp> | undefined;
const apiKey = import.meta.env.VITE_FIREBASE_API_KEY ?? 'AIzaSyB7-mB1e4mFUHzUzB87GOmNQRt8uX7OwiE';
const projectId = import.meta.env.VITE_FIREBASE_PROJECT_ID ?? 'aiart-market';
const storageBucket = import.meta.env.VITE_FIREBASE_STORAGE_BUCKET ?? 'aiart-market.appspot.com';
const messagingSenderId = import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID ?? '114269294416';
const appId = import.meta.env.VITE_FIREBASE_APP_ID ?? '1:114269294416:web:1c440132e90d16d05b2655';

if ('window' in globalThis) {
  if (!('randomUUID' in crypto)) {
    // polyfill crypto.randomUUID
    (crypto as any).randomUUID = () => {
      // generate random UUID
      // e.g. 7f9e5c2c-f2ef-4545-95d6-b700d7ec599e
      const random = Array.from(crypto.getRandomValues(new Uint8Array(16))).map((it) => it.toString(16).padStart(2, '0'));
      return `${random.slice(0, 4).join('')}-${random.slice(4, 6).join('')}-${random.slice(6, 8).join('')}-${random
        .slice(8, 10)
        .join('')}-${random.slice(10).join('')}`;
    };
  }

  function getAuthDomain() {
    if (!('location' in globalThis)) {
      return 'aifan.ai';
    }

    if ('hostname' in location) {
      if (location.hostname !== 'localhost') return location.hostname;

      return `${projectId}.firebaseapp.com`;
    }

    return 'aifan.ai';
  }

  const authDomain = getAuthDomain();

  // Your web app's Firebase configuration
  const firebaseConfig = {
    apiKey,
    authDomain,
    projectId,
    storageBucket,
    messagingSenderId,
    appId,
  };

  // Initialize Firebase
  __app = initializeApp(firebaseConfig);
}

export const app = __app;