import * as fba from 'firebase/auth';
import { app } from './initialization';
import { del, set } from '../utils/storage';

let auth: fba.Auth | null;

if (!app) {
  auth = null;
} else {
  auth = fba.getAuth(app);
}

export const authorized = (async () => {
  if (auth === null) {
    return null;
  }

  if (auth.currentUser !== null) {
    return auth.currentUser;
  }

  await fba.getRedirectResult(auth);

  if (auth.currentUser !== null) {
    const token = await fba.getIdToken(auth.currentUser);
    set('token', token);

    fba.onIdTokenChanged(auth, async (user) => {
      if (user === null) {
        del('token');
        return;
      }

      set('token', await fba.getIdToken(user));
    });
  } else {
    del('token');
  }

  return auth.currentUser;
})();

export const getIdToken = async () => {
  if (auth === null) {
    return null;
  }

  const user = await authorized;

  if (user === null) {
    return null;
  }

  return fba.getIdToken(user);
};

export const signingIn = async () => {
  if (auth === null) {
    return;
  }

  await fba.setPersistence(auth, fba.indexedDBLocalPersistence);

  const provider = new fba.GoogleAuthProvider();
  await fba.signInWithRedirect(auth, provider);
};

export const createAccount = async (email: string, password: string) => {
  if (auth === null) {
    return null;
  }

  return fba.createUserWithEmailAndPassword(auth, email, password);
};

export const signingInWithEmail = async (email: string, password: string) => {
  if (auth === null) {
    return null;
  }

  await fba.setPersistence(auth, fba.indexedDBLocalPersistence);

  return await fba.signInWithEmailAndPassword(auth, email, password);
};

export const signingOut = async () => {
  if (auth === null) {
    return;
  }

  del('token');
  fba.signOut(auth);
};
