import { authorized, getIdToken } from '../auth';
import type { JSONable } from '../common/jsonable';
import type { Plan, PlanSubscription, SubscriptionInfo } from '../common/plan';
import type { Channel, ChannelCreate, ChannelDetail } from '../common/user';

export type { Channel, ChannelDetail };

export async function createChannel(channel: ChannelCreate): Promise<string> {
  const token = await getIdToken();

  const resp = await fetch('/api/c', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(channel),
  });

  const json = await resp.json();

  return json.result;
}

export async function updateChannel(
  cid: string,
  channel: Partial<Omit<ChannelDetail, 'id' | 'created_at'>>,
) {
  const resp = await fetch(`/api/c/${cid}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await getIdToken()}`,
    },
    body: JSON.stringify(channel),
  });

  const json = await resp.json();

  if (json.status !== 204) {
    throw new Error(json.message);
  }
}

export async function getChannels(): Promise<Record<string, Channel>> {
  const token = await getIdToken();

  const resp = await fetch(`/api/user/channels`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const json = await resp.json();

  return json.result;
}

export async function getChannel(id: string, __fetch?: typeof fetch): Promise<ChannelDetail | null> {
  const _fetch = __fetch ? __fetch : fetch;

  const resp = await _fetch(`/api/c/${id}`);
  const json = await resp.json();

  return json.result ?? null;
}

export async function getChannelByHandle(handle: string, __fetch?: typeof fetch): Promise<ChannelDetail | null> {
  const _fetch = __fetch ? __fetch : fetch;

  const resp = await _fetch(`/api/c/h/${handle}`);
  const json = await resp.json();

  return json.result ?? null;
}

export async function existsChannel(handle: string): Promise<boolean> {
  return (await getChannelByHandle(handle)) !== null;
}

export async function getSubscriptionState(
  channel_id: string,
  subscriber: string,
  __fetch?: typeof fetch,
): Promise<PlanSubscription | null> {
  const _fetch = __fetch ? __fetch : fetch;
  await authorized;

  const token = await getIdToken();
  if (!token) return null;

  const resp = await _fetch(`/api/c/${channel_id}/subscription?cid=${subscriber}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const json = await resp.json();

  if (json.status !== 200) {
    return null;
  }

  return json.result ?? null;
}

export async function getSubscriptions(
  token?: string,
  __fetch?: typeof fetch,
): Promise<Record<string, JSONable<SubscriptionInfo>> | null> {
  const _fetch = __fetch ? __fetch : fetch;

  const resp = await _fetch(`/api/user/subscriptions`, {
    headers: {
      Authorization: `Bearer ${token ?? (await getIdToken())}`,
    },
  });

  const json = await resp.json();

  return json.result ?? null;
}

export async function getSubscribers(
  token?: string,
  __fetch?: typeof fetch,
): Promise<{ count: number; plan: JSONable<Plan> }[]> {
  const _fetch = __fetch ? __fetch : fetch;

  const resp = await _fetch(`/api/user/subscribers`, {
    headers: {
      Authorization: `Bearer ${token ?? (await getIdToken())}`,
    },
  });

  return await resp.json();
}

export async function subscriptionCancel(
  channel_id: string,
  subscriber: string,
  __fetch?: typeof fetch,
): Promise<void> {
  const _fetch = __fetch ? __fetch : fetch;

  const token = await getIdToken();
  if (!token) return;

  await _fetch(`/api/c/${channel_id}/subscription?cid=${subscriber}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: 'DELETE',
  });
}

function uuidString() {
  const chars = 'abcdefghijklmnopqrstuvwxyz0123456789';
  let uuid = BigInt('0x' + crypto.randomUUID().replace(/-/g, ''));
  let result = '';

  for (let i = 0; i < 22; i++) {
    result += chars[Number(uuid % BigInt(chars.length))];
    uuid /= BigInt(chars.length);
  }

  return result;
}

export async function createChannelAutomatic(allow_r18: boolean) {
  while (true) {
    const display_name = uuidString();
    if (!(await existsChannel(display_name))) {
      return await createChannel({
        display_name,
        allow_r18,
        bio: '',
        header: null,
        icon: null,
        name: 'ななしさん',
        twitter_url: null,
        web_url: null,
      });
    }
  }
}
