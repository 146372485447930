<script lang="ts">
  import { getChannels, type Channel, createChannelAutomatic } from '../firebase/api/channel';
  import { authorized, signingIn } from '../firebase/auth';
  import type { User } from 'firebase/auth';
  import CircularProgress from '@smui/circular-progress';
  import List, { Item, Graphic, Text, PrimaryText, Separator } from '@smui/list';
  import IconButton, { Icon } from '@smui/icon-button';
  import Menu from '@smui/menu';
  import defaultIcon from '../assets/default-icon.png';
  import { get, set } from '../utils/storage';
  import { onMount } from 'svelte';
  import { MEANINGLESS_CHANNEL_ID } from '../firebase/common/user';
  import { goto } from '../utils/navigation';
  import { signingOut } from '../firebase/auth';
  import { del } from '../utils/storage';

  import Dialog, { Actions, InitialFocus, Title } from '@smui/dialog';
  import Button from '@smui/button';

  let user: User | null = null;

  export let channels: Record<string, Channel> = {};
  export let currentCID: string | null = MEANINGLESS_CHANNEL_ID;
  export let onReady: () => void = () => {};
  export let disallowChangeChannel: boolean = false;
  export let needlessRedirectToCreateChannel: boolean = false;

  let dialogResult: ((result: boolean) => void) | null = null;
  let requestDialog: boolean;
  let portalRef: HTMLDivElement;
  $: requestDialog = dialogResult !== null;

  function userIsR18() {
    const parent = portalRef.parentElement;
    document.body.appendChild(portalRef);

    return new Promise<boolean>((resolve) => {
      dialogResult = (result) => {
        resolve(result);
        dialogResult = null;
        document.body.removeChild(portalRef);
      };
    });
  }

  onMount(() => {
    currentCID = JSON.parse(get('currentCID') ?? 'null');
  });

  const prom = (async () => {
    const u = await authorized;
    user = u;

    if (u !== null) {
      const c = await getChannels();
      channels = c;

      if (currentCID === null || !(currentCID in c)) {
        currentCID = Object.keys(c)[0] ?? null;
      }

      if (currentCID === null && !needlessRedirectToCreateChannel) {
        const isR18 = await userIsR18();
        const channel = await createChannelAutomatic(isR18);
        currentCID = channel;
        location.reload();
      }
    }
  })().then(onReady);

  $: {
    if (currentCID && currentCID !== MEANINGLESS_CHANNEL_ID) {
      set('currentCID', JSON.stringify(currentCID));
    }
  }

  let menu: Menu;
  let menuSp: Menu;
</script>

<!-- svelte-ignore a11y-no-static-element-interactions -->
<div
  style:z-index={100000}
  style:position="absolute"
  bind:this={portalRef}
  on:click|stopPropagation
  on:keydown|stopPropagation
  on:keyup|stopPropagation
  on:keypress|stopPropagation
  on:scroll|stopPropagation
>
  <Dialog bind:open={requestDialog}>
    <Title>あなたは18歳以上ですか？</Title>
    <Actions>
      <Button on:click={() => dialogResult?.(true)}>はい</Button>
      <Button on:click={() => dialogResult?.(false)} use={[InitialFocus]}>いいえ</Button>
    </Actions>
  </Dialog>
</div>

{#await prom}
  <CircularProgress style="height: 32px; width: 32px;" indeterminate />
{:then}
  {#if user === null}
    <button type="button" class="register" on:click={() => signingIn()}>ユーザー登録</button>
    <button type="button" class="login" on:click={() => signingIn()}>ログイン</button>
  {:else if currentCID !== null}
    <button class="user-card" on:click={() => menu.setOpen(!menu.isOpen())}>
      <div class="user-avatar">
        <img src={channels[currentCID]?.icon ?? defaultIcon} alt={`${channels[currentCID]?.name} avatar`} />
      </div>
      <Menu bind:this={menu} anchorCorner="BOTTOM_START" on:click={(e) => e.stopPropagation()}>
        <List avatarList singleSelection>
          {#each Object.entries(channels) as [id, channel]}
            <Item
              on:SMUI:action={() => {
                currentCID = id;
              }}
              selected={currentCID === id}
              disabled={disallowChangeChannel && currentCID !== id}
            >
              <Graphic style="background: no-repeat center/cover url({channel.icon ?? defaultIcon});" />
              <Text style="height: 100%; max-width: 10rem;">
                <PrimaryText>{channel.name}</PrimaryText>
              </Text>
            </Item>
          {/each}
          <Item
            on:SMUI:action={() => {
              goto('/new');
            }}
          >
            <Text style="height: 100%;">
              <PrimaryText><i class="material-symbols-outlined inline">add</i>チャンネルを追加する</PrimaryText>
            </Text>
          </Item>
        </List>
      </Menu>
    </button>
    <IconButton class="sp-nav" on:click={() => menuSp.setOpen(!menuSp.isOpen())} touch>
      <Icon class="material-symbols-outlined">dehaze</Icon>
      <Menu bind:this={menuSp} anchorCorner="BOTTOM_LEFT" on:click={(e) => e.stopPropagation()}>
        <List singleSelection>
          <Item>
            <a href="/" class="link-text" on:click={() => menuSp.setOpen(false)} data-sveltekit-preload-code="eager">
              支援中タイムライン
            </a>
          </Item>
          <Item>
            <a
              href="/supporting"
              class="link-text"
              on:click={() => menuSp.setOpen(false)}
              data-sveltekit-preload-code="eager"
            >
              支援中のクリエイター
            </a>
          </Item>
          <Separator />
          <Item>
            <a
              href="/settings"
              class="link-text"
              on:click={() => menuSp.setOpen(false)}
              data-sveltekit-preload-code="eager"
            >
              チャンネル設定
            </a>
          </Item>
          <Item>
            <a
              href="/settings/earnings"
              class="link-text"
              on:click={() => menuSp.setOpen(false)}
              data-sveltekit-preload-code="eager"
            >
              支援金管理
            </a>
          </Item>
          <Item>
            <a
              href="/c/{channels[currentCID]?.display_name}"
              class="link-text"
              on:click={() => menuSp.setOpen(false)}
              data-sveltekit-preload-code="eager"
            >
              投稿管理
            </a>
          </Item>
          <Item>
            <a
              href="/c/{channels[currentCID]?.display_name}?tab=plans"
              class="link-text"
              on:click={() => menuSp.setOpen(false)}
              data-sveltekit-preload-code="eager"
            >
              プラン管理
            </a>
          </Item>
          <Item>
            <a
              href="/feeds/new"
              class="link-text"
              on:click={() => menuSp.setOpen(false)}
              data-sveltekit-preload-code="eager"
            >
              投稿する
            </a>
          </Item>
          <Item>
            <a
              href="."
              on:click={(e) => {
                e.preventDefault();
                del('currentCID');
                signingOut();
                goto('/');
                location.reload();
              }}
              class="link-text">ログアウト</a
            >
          </Item>
          <Separator />
          <Item>
            <a href="/tos" class="link-text" on:click={() => menuSp.setOpen(false)}> 利用規約 </a>
          </Item>
          <Item>
            <a href="/privacy-policy" class="link-text" on:click={() => menuSp.setOpen(false)}>
              プライバシーポリシー
            </a>
          </Item>
          <Item>
            <a href="/scta" class="link-text" on:click={() => menuSp.setOpen(false)}> 特定商取引法に基づく表記 </a>
          </Item>
        </List>
      </Menu>
    </IconButton>
  {/if}
{/await}

<style lang="scss">
  $sp-width: 456px;
  $tb-width: 880px;
  .material-symbols-outlined.inline {
    position: relative;
    top: 6px;
  }
  .user-card {
    display: flex;
    height: 3rem;
    background: transparent;
    border: none;
    grid-column-gap: 0.5rem;
    font-weight: 500;

    .user-avatar {
      img {
        height: 3rem;
        width: 3rem;
        border-radius: 9in;
      }
    }
  }

  button {
    transition: 200ms;
  }

  button:hover {
    filter: brightness(1.2);
  }

  button:active {
    filter: brightness(0.8);
  }

  .register {
    appearance: none;
    border: none;
    background: var(--primary-color);
    display: flex;
    height: 2.4rem;
    padding: 0 1.5rem;
    border-radius: 9in;
    text-decoration: none;
    color: white;
    margin: auto;
    line-height: 2.4rem;
    font-weight: bold;
  }

  .login {
    appearance: none;
    border: none;
    background: white;
    display: flex;
    height: 2.4rem;
    padding: 0 1.5rem;
    border-radius: 9in;
    text-decoration: none;
    color: #fff;
    margin: auto;
    font-weight: bold;
    line-height: 2.4rem;
    background-color: var(--primary-color-light);
  }
  :global(.sp-nav) {
    display: none;
  }
  @media screen and (max-width: #{$tb-width}) {
    :global(.sp-nav) {
      display: flex;

      a {
        display: inline-block;
        width: 100%;
        text-align: left;
        padding: 1rem 0;
      }
    }
    .user-card {
      height: 2.5rem;
      padding: 0.25rem 0;

      .user-avatar {
        img {
          height: 2.5rem;
          width: 2.5rem;
        }
      }
    }

    .register {
      height: 2.2rem;
      line-height: 2.2rem;
    }

    .login {
      height: 2.2rem;
      line-height: 2.2rem;
    }
    .link-text {
      color: var(--black);
      text-decoration: none;
      word-break: keep-all;
      font-weight: 500;
    }
  }
</style>
