import { goto as __goto } from '$app/navigation';

export const goto = async (
  url: string | URL,
  opts?: {
    replaceState?: boolean;
    noScroll?: boolean;
    keepFocus?: boolean;
    state?: any;
    invalidateAll?: boolean;
  },
): Promise<void> => {
  if (typeof url === 'string') {
    url = new URL(url, location.href);
  }

  if (url.pathname !== location.pathname) {
    window.dispatchEvent(new CustomEvent('start-transition', { detail: { url } }));
  }

  return await __goto(url, opts);
};
